// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import Choices from 'choices.js'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "bootstrap";
require("trix")
require("@rails/actiontext")

document.addEventListener("turbolinks:load", function () {
  const elements = document.querySelectorAll('.choices-js');
  elements.forEach(el => {
    new Choices(el, { removeItemButton: true, shouldSort: false });
  });
})

function initializeCharts(event) {
  const chartDivs = document.querySelectorAll('.chartdiv');

  chartDivs.forEach(chartDiv => {
    const reportData = chartDiv.dataset.report;
    if (reportData) {
      chartdiv(JSON.parse(reportData), chartDiv.id);
    }
  });
}

document.addEventListener("DOMContentLoaded", initializeCharts);
document.addEventListener("turbolinks:load", initializeCharts);